import { reactive } from 'vue';
// import colorJson from '@/assets/json/color.json';

export default () => {
    const state = reactive({
        // colorList: colorJson,// 颜色合集
        resColorList: [],// 列表二纬集合
    })

    // 随机生成十六进制颜色
    function randomHexColor() {
        let hex = Math.floor(Math.random() * 16777216).toString(16); //生成ffffff以内16进制数
        while (hex.length < 6) { //while循环判断hex位数，少于6位前面加0凑够6位
            hex = '0' + hex;
        }
        return '#' + hex; //返回‘#'开头16进制颜色
    }

    // 颜色数据处理
    function colorHandel(list = []) {
        let colorIds = list.map(item => item.list)
        colorIds = colorIds.flat()
        colorIds = colorIds.map(item => item.color)
        colorIds = [...new Set(colorIds)]
        const colorMap = {}// 颜色映射
        colorIds.forEach(item => {
            if (item >= 0) {
                // colorMap[item] = state.colorList[index]
                colorMap[item] = randomHexColor()
            }
        })
        setColor(list, colorMap)
    }

    // 赋值颜色
    function setColor(list, colorMap) {
        state.resColorList = list.map(item => {
            item.list = item.list.map(item2 => {
                if (item2.color >= 0) {
                    item2['backColor'] = colorMap[item2.color]
                }
                return item2
            })
            return item
        })
    }

    // 样式计算
    function styleCalc(row) {
        const styleObj = {}
        if (row.color >= 0) {
            styleObj['background'] = row.backColor;
            styleObj['color'] = '#fff';
        }
        if (row.color === -2) {
            styleObj['border'] = '2px dashed #ccc';
        }
        return styleObj
    }

    return { state, colorHandel, styleCalc }
}