<template>
    <el-dialog :model-value="show" title="图片选择（单选）" width="1000px" @close="close">
        <span>菜名：{{ dishName }}</span>
        <div class="img_list">
            <div class="li" :class="item.isChecked ? 'acitve' : ''" v-for="(item, index) in imgList" :key="item.id"
                @click="onChoose(index)">
                <el-image :src="item.url" fit="cover"></el-image>
                <el-icon class="icon">
                    <CircleCheckFilled />
                </el-icon>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="submit">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { CircleCheckFilled } from '@element-plus/icons-vue'

const props = defineProps({
    show: { type: Boolean, required: true },
    list: { type: Array, required: true },
    dishName: { type: String, default: '' }
})

const emits = defineEmits(['close', 'submitChooseImg'])

const imgList = ref([])

watch(() => props.list, (val) => {
    if (props.show) {
        imgList.value = val
    }
})

// 选择
function onChoose(index) {
    imgList.value = imgList.value.map((item, i) => {
        item.isChecked = false;
        if (i === index) {
            item.isChecked = !item.isChecked
        }
        return item
    })
}

// 提交
function submit() {
    const url = (imgList.value.find(item => item.isChecked)).url
    emits('submitChooseImg', url)
}

function close() {
    emits('close')
}
</script>

<style scoped lang="scss">
.img_list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .li {
        width: 20%;
        aspect-ratio: 1/1;
        border: 5px solid #fff;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        background-color: #f2f2f2;

        .el-image {
            width: 100%;
            height: 100%;
        }

        &.acitve {
            border-color: #67C23A;
            box-shadow: 0 0 10px #ccc;
            position: relative;

            .icon {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 2;
                font-size: 40px;
                color: #67C23A;
            }
        }
    }
}
</style>