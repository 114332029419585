<template>
    <div class="report_result">
        <div class="main">
            <section class="box le_box">
                <div class="tit">
                    <small-title :tit="`“${state.imgPageName}”区域${reportTypeNow.name}TOP${state.configSize}`"
                        :fontSize="16">
                    </small-title>
                </div>
                <div class="img_list scrollbar">
                    <div class="li" v-for="item in state.imageList" :key="item">
                        <div class="dish_name textOverflowOne">{{ item.name }}</div>
                        <div class="dish_img">
                            <el-image fit="cover" :src="item.img" :preview-src-list="[item.img]">
                            </el-image>
                            <div class="operation">
                                <div class="icon_box" @click="onImgHandel($event)">
                                    <el-icon class="icon">
                                        <Search />
                                    </el-icon>
                                </div>
                                <div class="icon_box" @click="dicOpen(item)" v-if="!state.confirm">
                                    <el-icon class="icon">
                                        <Edit />
                                    </el-icon>
                                </div>
                            </div>
                        </div>
                        <div class="num">{{ reportTypeNow.imgKey }}：{{ item[reportTypeNow.valKey] || 0 }}
                            <span v-if="reportTypeNow.valKey === 'yoyRatio'">%</span>
                        </div>
                    </div>
                </div>
            </section>
            <section class="box ri_box">
                <div class="tit">
                    <small-title :tit="`区域${reportTypeNow.name}TOP${state.configSize}`" :fontSize="16"></small-title>
                </div>
                <div class="list_box scrollbar">
                    <div class="col_box" v-for="item in stateColor.resColorList" :key="item">
                        <div class="name_box">{{ item.name }}</div>
                        <div class="list scrollbar">
                            <div class="li" v-for="item2 in item.list" :key="item2" :style="styleCalc(item2)">{{
                                    item2.name
                            }}</div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <el-button class="confirm_btn" type="primary" v-if="!state.confirm" @click="confirmReport">确认生成报告</el-button>
    </div>
    <!-- 菜品图片选择弹框 -->
    <dish-img-choose-dialog :show="dicState.show" @close="dicClose" :list="dicState.imgList"
        :dishName="dicState.row.name" @submitChooseImg="submitChooseImg"></dish-img-choose-dialog>
</template>

<script setup>
import SmallTitle from '@/components/common/SmallTitle.vue';
import { Search, Edit } from '@element-plus/icons-vue';
import { computed, onBeforeMount, reactive } from 'vue';
import DishImgChooseDialog from './DishImgChooseDialog.vue';//菜品图片选择弹框
import useDishImgChooseDialog from './useDishImgChooseDialog';
import { useRoute } from 'vue-router';
import { reportResult, reportComplete } from '@/request/dishApi';
import useColor from './useColor';
import { ElLoading, ElMessage } from 'element-plus';

const $route = useRoute()
const state = reactive({
    reportId: $route.query.id,
    imageList: [],// 图片集合
    confirm: true,// 报告是否生成：true=已确认，false=未确认
    reportType: 'TOP',// 报告类型：TOP=热卖推荐菜，UP=趋势增长菜
    imgPageName: '',//图片区域名称
    configSize: 0,// 报告菜品配置数量
})

const reportTypeNow = computed(() => {
    const obj = {
        TOP: { name: '热卖推荐菜', imgKey: '推荐值', valKey: 'recommendsNum' },
        UP: { name: '趋势增长菜', imgKey: '推荐值GR', valKey: 'yoyRatio' }
    }
    return obj[state.reportType]
})


// 获取报告结果
async function getData() {
    const loading = ElLoading.service()
    const { data } = await reportResult({ reportId: state.reportId })
    state.imageList = data?.imageList || []
    state.confirm = data?.confirm
    state.reportType = data?.reportType || 'TOP'
    state.imgPageName = data?.imgPageName || ''
    state.configSize = data?.configSize || 0
    colorHandel(data?.colList)
    loading.close()
}

// 图片选择
const { state: dicState, close: dicClose, open: dicOpen, submitChooseImg } = useDishImgChooseDialog();

// 点击放大图片
function onImgHandel(e) {
    const imgDom = e.currentTarget.parentElement.previousElementSibling.firstElementChild;
    imgDom.click()
}

// 右边列表颜色处理
const { state: stateColor, colorHandel, styleCalc } = useColor()

// 确认生成报告
async function confirmReport() {
    const loading = ElLoading.service()
    const { code } = await reportComplete({ id: state.reportId })
    if (code === '0') {
        ElMessage({ type: 'success', message: '报告已生成' })
        getData()
    }
    setTimeout(() => { loading.close() }, 500)
}

onBeforeMount(() => {
    getData()
})
</script>

<style lang="scss" scoped>
.report_result {
    .main {
        display: flex;
        justify-content: space-between;
        // height: calc(100vh - 60px);
        padding: 20px;

        .box {
            width: 49%;
            height: 100%;
            background-color: #fff;
            border-radius: 10px;
            position: relative;
            padding-top: 60px;

            .tit {
                height: 60px;
                border-bottom: 1px solid #f2f2f2;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0 20px;
                color: #666;
            }
        }

        .le_box {
            .img_list {
                width: 100%;
                height: 100%;
                overflow-y: auto;
                padding: 15px;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: auto;
                column-gap: 10px;
                row-gap: 10px;

                .li {
                    // width: 100%;
                    min-width: 0;
                    aspect-ratio: 1/1;
                    border: 1px solid #f2f2f2;
                    border-radius: 4px;

                    .dish_name {
                        width: 100%;
                        font-size: 14px;
                        color: #666;
                        padding: 10px 5px;
                    }

                    .dish_img {
                        width: 100%;
                        aspect-ratio: 1.55/1;
                        position: relative;

                        .el-image {
                            width: 100%;
                            height: 100%;
                        }

                        .operation {
                            display: none;
                        }

                        &:hover {
                            .operation {
                                display: flex;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                background-color: rgba($color: #000, $alpha: .3);
                                top: 0;
                                left: 0;
                                align-items: center;
                                justify-content: center;

                                .icon_box {
                                    width: 30px;
                                    height: 30px;
                                    background-color: #fff;
                                    border-radius: 50%;
                                    margin: 0 5px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .icon {
                                        color: #6387e1;
                                        font-size: 16px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }

                    .num {
                        font-size: 14px;
                        color: #666;
                        padding: 10px 5px;

                    }
                }
            }
        }

        .ri_box {
            .list_box {
                width: 100%;
                height: 100%;
                overflow-x: auto;
                padding: 15px;
                display: flex;

                &::-webkit-scrollbar {
                    height: 10px;
                }

                .col_box {
                    display: inline-flex;
                    flex-direction: column;
                    flex-shrink: 0;
                    width: 200px;
                    height: 100%;
                    border: 1px solid #f2f2f2;
                    margin-right: 15px;
                    border-radius: 4px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .name_box {
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        font-size: 14px;
                        color: #666;
                        padding: 0 10px;
                        border-bottom: 1px solid #f2f2f2;
                        text-align: center;
                        background-color: #f2f2f2;
                    }

                    .list {
                        flex: 1;
                        overflow-y: auto;
                        padding: 10px;

                        .li {
                            width: 100%;
                            border: 2px solid #ccc;
                            margin-bottom: 10px;
                            padding: 2px 5px;
                            text-align: center;
                            border-radius: 4px;
                            color: #666;
                        }
                    }
                }
            }
        }
    }

    .confirm_btn {
        position: fixed;
        bottom: 20px;
        right: 0;
        left: 0;
        margin: auto;
        width: 150px;
        box-shadow: 0 0 10px #ccc;
    }

}
</style>